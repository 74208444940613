import React from 'react';
import { ProcessContainer, ProcessWrapper, ProcessSlider, ProcessSlide, ProcessTitle, ProcessDescription, ProcessHeading, ProcessNumber, PathSection, PathTitle, PathDescription, CheckList, CheckItem, ActivationFeesBox, FeesTitle, FeesList, FeesItem, PathsContainer} from './ProcessElements';

const ProcessSection = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  const processSectionData = {
    id: 'process',
    lightBg: false,
    headline: 'OUR PROCESS',
    steps: [
      {
        title: "Choose Evaluation Account Type & Size",
        description: [
          "Complete your registration with Humble Funding. Purchase an evaluation account with the account type and size you prefer. No background check or legal details are required for this step."
        ]
      },
      {
        title: "Download Partner Technologies",
        description: [
          "Download and install R Trader Pro. Afterwards, download your preferred trading platform and sign in using the Rithmic credentials provided by Humble Funding."
        ]
      },
      {
        title: "Qualify For A Performance Account",
        description: [
          "Begin your journey by trading your evaluation account. Upon successfully passing and showcasing your risk management skills, proceed to verification. Once verified, choose between a standard or premium performance account."
        ]
      },
      {
        title: "Become Funded With Humble Funding",
        description: [
          "Trade on your Performance Account and prove your success. Achieve the established profit target and enjoy up to a 100% profit split up till the first $25K, with 90% thereafter. Benefit from swift payouts, 24/7 live support, and the ability to copy trade up to 25 Performance Accounts simultaneously with our streamlined one-step evaluation."
        ]
      }
    ]
  };

  const paths = [
    {
      name: 'HUMBLE BEGINNING',
      description: 'Humble Beginnings represents the Standard Path, following the traditional route of purchasing evaluation accounts and activation fees. This path includes revolving discounts exclusive to evaluation and activation fees, and once passed, traders can choose between a Standard or Premium Performance Account with varying payout request limits. It’s ideal for traders looking to take their time, build confidence, and work towards their first 25K payout.',
      features: [
        'Consistency Rule on Evaluation and Performance Accounts',
        'Revolving Discount on Evaluation Accounts & Activation Fees',
        'Option to Promote to Premium Performance Account',
      ],
      themeColor: 'bronze', // Bronze
      textColor: '#fff',
      iconColor: '#fff',
    },
    {
      name: 'HUMBLE EXPERT',
      description: 'Humble Expert represents the Express Path, which excludes activation fees and allows traders to move directly from evaluation to performance accounts through a monthly subscription. Traders get one free reset per account, with resets shared from a limited pool across all Express Path users. There is no consistency rule on evaluation accounts, but a 30% rule applies to Performance Accounts. This path suits confident traders aiming to accelerate their journey to becoming funded and reaching their first 25K payout.',
      features: [
        'No Activation Fees',
        'Consistency Rule Only on Performance Accounts',
        'Shared Pool of Free Resets',
      ],
      themeColor: 'silver', // Silver
      textColor: '#000',
      iconColor: '#000',
    },
    {
      name: 'HUMBLE SUMMIT',
      description: 'Humble Summit represents the Instant Funding Path, where traders gain immediate access to a Performance Account with a one-time fee. Designed for experienced traders who have been funded multiple times, this path includes a 30% consistency rule on the performance account. It’s tailored for skilled traders who can consistently pass evaluations, allowing Humble Funding to place them into live accounts and support their growth with funds from successful payouts.',
      features: [
        'Instant Funding',
        'Consistency Rule on Performance Accounts',
        'One-Time Fee',
      ],
      themeColor: 'gold', // Gold
      textColor: '#4f4f4f',
      iconColor: '#000',
    },
  ];
  

  return (
    <ProcessContainer id={processSectionData.id}>
      <ProcessWrapper>
        <ProcessHeading>{processSectionData.headline}</ProcessHeading>
        <ProcessSlider {...settings}>
          {processSectionData.steps.map((step, index) => (
            <ProcessSlide key={index}>
              <ProcessNumber>{String(index + 1).padStart(2, '0')}</ProcessNumber>
              <ProcessTitle>{step.title}</ProcessTitle>
              <ProcessDescription>
                {step.description.map((desc, itemIndex) => <p key={itemIndex}>{desc}</p>)}
              </ProcessDescription>
            </ProcessSlide>
          ))}
        </ProcessSlider>
        
        <PathsContainer>
        {paths.map((path, index) => (
          <PathSection key={index} themeType={path.themeColor} textColor={path.textColor}>
            <PathTitle textColor={path.textColor}>{path.name}</PathTitle>
            <PathDescription textColor={path.textColor}>{path.description}</PathDescription>
            <CheckList textColor={path.textColor}>
              {path.features.map((feature, i) => (
                <CheckItem key={i} iconColor={path.iconColor}>{feature}</CheckItem>
              ))}
            </CheckList>
          </PathSection>
        ))}
      </PathsContainer>
        
        <ActivationFeesBox>
          <FeesTitle>ONLY FOR THE STANDARD PATH</FeesTitle>
          <FeesList>
            <FeesItem>$79.99 Monthly Activation Fee</FeesItem>
            <FeesItem>$180 Lifetime + One Day Pass Fee (Standard Performance Account)</FeesItem>
            <FeesItem>$210 Lifetime + One Day Pass Fee (Premium Performance Account)</FeesItem>
          </FeesList>
        </ActivationFeesBox>

      </ProcessWrapper>
    </ProcessContainer>
  );
}

export default ProcessSection;
