import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  font-family: Arial, sans-serif;
  color: #333;
  padding: 20px;
  max-width: 960px;
  margin: 0 auto;
  background: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`;

const Title = styled.h1`
  background: linear-gradient(90deg, black, #00bfff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;  font-size: 28px;
  text-align: center;
  margin-bottom: 20px;
  text-transform: uppercase;
`;

const Table = styled.table`
  width: 100%;
  background: linear-gradient(90deg, black, #00bfff);
  border-collapse: collapse;
  margin: 20px 0;
  background: white;
  border-radius: 10px;
`;

const Th = styled.th`
  padding: 15px;
  border-bottom: 1px solid #ddd;
  background: linear-gradient(90deg, black, #00bfff);
  color: white;
  text-align: left;
  text-transform: uppercase;
`;

const Td = styled.td`
  padding: 15px;
  border-bottom: 1px solid #ddd;
  color: ${props => (props.highlight ? '#00bfff' : 'black')};
`;

const InfoText = styled.p`
  line-height: 1.6;
  margin: 20px 0;
`;

const Highlight = styled.span`
  color: linear-gradient(90deg, black, #00bfff);
  font-weight: bold;
`;

const WithdrawalGuidelines = () => {
  return (
    <Container>
      <Title>Payout Guidelines</Title>
      
      <InfoText>
        Humble Funding is committed to innovating the trading industry by providing transparent and fair withdrawal guidelines. Below is a structured guideline for minimum account balances, trading days requirement, maximum withdrawal limits, and other essential details for each account size:
      </InfoText>
      
      <Table>
        <thead>
          <tr>
            <Th>Account Size</Th>
            <Th>Minimum Balance: Standard PA</Th>
            <Th>Minimum Balance: Premium PA</Th>
            <Th>Trading Days Required</Th>
            <Th>Maximum Payout Per Standard PA</Th>
            <Th>Maximum Payout Per Premium PA</Th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <Td>$25,000 Account</Td>
            <Td highlight>$26,500</Td>
            <Td highlight>$27,500</Td>
            <Td>8</Td>
            <Td highlight>$500 per payment period</Td>
            <Td highlight>$1,500 per payment period</Td>
          </tr>
          <tr>
            <Td>$50,000 Account</Td>
            <Td highlight>$52,500</Td>
            <Td highlight>$53,500</Td>
            <Td>8</Td>
            <Td highlight>$1,000 per payment period</Td>
            <Td highlight>$1,750 per payment period</Td>
          </tr>
          <tr>
            <Td>$75,000 Account</Td>
            <Td highlight>$78,500</Td>
            <Td highlight>$79,500</Td>
            <Td>8</Td>
            <Td highlight>$1,250 per payment period</Td>
            <Td highlight>$1,850 per payment period</Td>
          </tr>
          <tr>
            <Td>$100,000 Account</Td>
            <Td highlight>$105,500</Td>
            <Td highlight>$106,500</Td>
            <Td>8</Td>
            <Td highlight>$1,750 per payment period</Td>
            <Td highlight>$2,000 per payment period</Td>
          </tr>
          <tr>
            <Td>$150,000 Account</Td>
            <Td highlight>$158,000</Td>
            <Td highlight>$159,500</Td>
            <Td>8</Td>
            <Td highlight>$2,000 per payment period</Td>
            <Td highlight>$2,500 per payment period</Td>
          </tr>
          <tr>
            <Td>$300,000 Account</Td>
            <Td highlight>$319,000</Td>
            <Td highlight>$320,000</Td>
            <Td>8</Td>
            <Td highlight>$3,000 per payment period</Td>
            <Td highlight>$3,500 per payment period</Td>
          </tr>
        </tbody>
      </Table>
      
      <InfoText>
      This withdrawal policy promotes active trading and ensures financial stability within accounts. It provides traders with a clear understanding of their withdrawal capacities and requirements. Humble Funding offers both Standard Performance Accounts (PA) and Premium Performance Accounts (PA). Standard PAs allow traders to receive their payout immediately upon request approval, with an option to choose a 3-month payout spread that includes 2 free evaluation account resets. Premium PAs allow for higher payout requests, and if traders opt for the 2-month payout spread, they receive an added benefit of 3 free evaluation account resets.
    </InfoText>

      <InfoText>
        <Highlight>Extended Withdrawal Benefits:</Highlight> From the fourth month of withdrawals for the same account, there is no maximum limit on the withdrawal amount, provided the account maintains the required minimum balance.
      </InfoText>
    </Container>
  );
};

export default WithdrawalGuidelines;
