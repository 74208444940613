import React from 'react';
import { InfoContainer, InfoWrapper, CardsContainer, Card, CardTitle, CardContent, TopLine, Heading, CardIcon } from './AboutElements';
import CheckSquareIcon from '../../images/check-square.svg'; 
import briefcase from '../../images/briefcase.svg'; 
import clock from '../../images/clock.svg'; 
import gift from '../../images/gift.svg'; 
import flex from '../../images/maximize-2.svg'; 
import cash from '../../images/dollar-sign.svg'; 

const AboutSection = () => {
  const aboutSectionData = {
    id: 'about',
    lightBg: false,
    topLine: '',
    headline: 'THE HUMBLE FUNDING ADVANTAGE',
    description: [
      {
        title: "Guaranteed Payout Process",
        icon: CheckSquareIcon,
        items: [
          "Traders now have the opportunity to receive their payout immediately on the same day their payout request is approved, ensuring no delay in accessing their profits. For those who prefer, the option to choose a payout spread over a 3-month period remains available. By selecting the payout spread, traders will also benefit from 2 free evaluation account resets, adding extra flexibility to their trading experience.",
        ]
      },
      {
        title: "Premium Performance Account Offer",
        icon: briefcase, // Replace with your specific SVG
        items: [
          "Introducing our Premium Performance Account, which not only allows traders to make larger payout requests but also provides enhanced benefits tailored to their needs. Traders also have the option of picking the 2-month payout spread with this account, where traders will receive 3 free evaluation account resets, enabling them to continue refining their strategies with added support. This exclusive offering ensures that our traders have the tools they need to maximize their success.",
        ]
      },
      {
        title: "24/7 Live Email & Discord Support",
        icon: clock, // Replace with your specific SVG
        items: [
          "Enjoy round-the-clock support tailored for our traders through Email and Discord. Whether you need help with account management, trading strategies, or technical issues, our dedicated support team is available at all times. Join our active Discord community to connect with other traders, share insights, and receive real-time assistance through our live-ticket system",
        ]
      },
      {
        title: "Free Tier 1 Data",
        icon: gift, // Replace with your specific SVG
        items: [
          "Get essential market data for free with any PA funded or evaluation account purchase",
        ]
      },
      {
        title: "Flexible Withdrawal Window",
        icon: flex, // Replace with your specific SVG
        items: [
          "Payout requests can be submitted from the 1st to the 4th of every month, with no size restrictions. We plan on adding an additional window soon.",
        ]
      },
      {
        title: "Express Funding",
        icon: cash, // Replace with your specific SVG
        items: [
          "Get funded fast with Express Funding—the all-in-one solution for traders! No activation fees, no separate purchases for evaluation—just a simple monthly subscription that gets you trading instantly and on the quickest path to your first $25K payday.",
        ]
      }
    ]
  };

  return (
    <InfoContainer lightBg={aboutSectionData.lightBg} id={aboutSectionData.id}>
      <InfoWrapper>
        <TopLine>{aboutSectionData.topLine}</TopLine>
        <Heading>{aboutSectionData.headline}</Heading>
        <CardsContainer>
          {aboutSectionData.description.map((desc, index) => (
            <Card key={index}>
              <CardIcon src={desc.icon} />
              <CardTitle>{desc.title}</CardTitle>
              <CardContent>
                {desc.items.map((item, itemIndex) => <p key={itemIndex}>{item}</p>)}
              </CardContent>
            </Card>
          ))}
        </CardsContainer>
      </InfoWrapper>
    </InfoContainer>
  );
}

export default AboutSection;
