import React from 'react';
import styled, { keyframes } from 'styled-components';

const gradientText = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const Container = styled.div`
  font-family: 'Roboto', sans-serif;
  padding: 40px 20px;
  max-width: 960px;
  margin: 0 auto;
  background: #282c34;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  color: #fff;
`;

const Title = styled.h2`
  font-size: 32px;
  background: linear-gradient(90deg, white, #00bfff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  text-align: center;
  margin-bottom: 30px;
  animation: ${gradientText} 3s ease infinite;
`;

const Subtitle = styled.h3`
  font-size: 24px;
  background: linear-gradient(90deg, #89f7fe, #66a6ff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  margin-top: 30px;
  animation: ${gradientText} 3s ease infinite;
`;

const Section = styled.section`
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 2px solid #444;
  &:last-child {
    border-bottom: none;
  }
`;

const Paragraph = styled.p`
  font-size: 18px;
  line-height: 1.6;
  margin: 20px 0;
`;

const TermsAndConditions = () => {
  return (
    <Container>
      <Title>Terms and Conditions</Title>

      <Section>
        <Subtitle>User Agreement and Eligibility</Subtitle>
        <Paragraph>
        When engaging with Humble Funding, users enter into a binding agreement, confirming they are at least 18 years old. Users must familiarize themselves with the services, including evaluation and PA programs, before making any purchases. By purchasing a product, users acknowledge and accept all terms and conditions.        </Paragraph>
      </Section>

      <Section>
        <Subtitle>Liability, Warranty Disclaimer, and User Indemnification</Subtitle>
        <Paragraph>
         Humble Funding and its affiliates provide all services "as is" without any warranties, express or implied. We do not guarantee uninterrupted access to our services or that our services will meet your requirements or expectations. The COMPANY is not liable for any indirect, incidental, special, or consequential damages that may arise from the use of our services. This includes, but is not limited to, any loss of profits, data, or other intangible losses. Users agree to indemnify and hold harmless the COMPANY, its affiliates, and their respective officers, directors, employees, and agents from any claims, losses, damages, liabilities, costs, or expenses (including legal fees) arising from or related to their use of the services, violation of these terms, or infringement of any third-party rights. Users are encouraged to use our services responsibly and to be aware of the inherent risks involved.        </Paragraph>
      </Section>

      <Section>
        <Subtitle>Educational Purpose and Risk Awareness</Subtitle>
        <Paragraph>
        Humble Funding's services are designed for educational purposes only, highlighting the significant risks involved in trading. Users should seek advice from licensed financial professionals before making any investment decisions. Trading carries inherent risks, and it's crucial to be fully informed before proceeding. Humble Funding does not guarantee profits or protection from losses.        </Paragraph>
      </Section>

      <Section>
        <Subtitle>Professional Data Services</Subtitle>
        <Paragraph>
        Opting for professional data services will incur an additional monthly charge of $150, covering the entire month irrespective of the subscription date. Users are responsible for manually renewing these services. Non-professional users are exempt from this $150 charge.        </Paragraph>
      </Section>

      <Section>
        <Subtitle>User Identity and Conduct</Subtitle>
        <Paragraph>
        Users must use their real, legal names and refrain from impersonating others or sharing their account details. Our services are for personal use, and compliance with all applicable laws is mandatory. Misuse or unauthorized sharing of account information can result in account termination and potential legal action.        </Paragraph>
      </Section>

      <Section>
        <Subtitle>Additional Service Fees</Subtitle>
        <Paragraph>
        Certain services, such as extra session access or upgraded market data, incur additional monthly charges. These services must be manually renewed. Users are responsible for maintaining accurate and up-to-date personal and payment information.        </Paragraph>
      </Section>

      <Section>
        <Subtitle>Trading Risk Advisory</Subtitle>
        <Paragraph>
        Investing and trading carry substantial risks, potentially resulting in significant losses that may exceed initial investments. It is crucial for individuals to only invest funds they can afford to lose and to understand the inherent risks. Past performance of investment strategies does not guarantee future success. This principle applies to both live and hypothetical trading scenarios, which may not fully reflect market conditions due to factors like liquidity and commissions. Before making any investment decisions, consult with a broker for accurate pricing information. Humble Funding LLC provides services and content for educational purposes only and does not offer professional financial advice.
        </Paragraph>
      </Section>

      <Section>
        <Subtitle>Independent Contractor Policy</Subtitle>
        <Paragraph>
        For effective risk management, all PA accounts at Humble Funding LLC commence in a simulated environment, ensuring sustained performance over time. Customers are designated as independent contractors rather than traditional account holders. They trade within this simulated funding environment, which operates without using actual live funds.        </Paragraph>
      </Section>

      <Section>
        <Subtitle>No-Refund Policy</Subtitle>
        <Paragraph>
        Humble Funding upholds a stringent no-refund policy. Once a subscription is initiated, it starts immediately and follows a 30-day billing cycle. Users are responsible for overseeing their subscriptions and must cancel them before the renewal date through either emailing Humble Funding at support@humblefunding.co or opening a support ticket in Humble Fundings discord. Refunds will not be issued for any partial service periods.      </Paragraph>
      </Section>
      <Section>
        <Subtitle>Privacy and Data Use</Subtitle>
        <Paragraph>
        Humble Funding values user privacy and adheres to all relevant laws. Personal information is utilized exclusively within Humble Funding and is only disclosed as legally mandated.        </Paragraph>
      </Section>

      <Section className="no-border">
        <Subtitle>Account Management and Terms Compliance</Subtitle>
        <Paragraph>
        Users must manage their account settings and adhere to the terms of service. Humble Funding reserves the right to alter services or terms at any time. Continued use of the services after any changes implies acceptance of the new terms. Any updates to the terms of service or the company's FAQ will apply to all customers, regardless of when they initially purchased the service.        </Paragraph>
      </Section>
    </Container>
  );
};

export default TermsAndConditions;
