import React, { useState } from 'react';
import { Button } from '../ButtonElements';
import SaleTimer from './SaleTimer';
import StarImageSrc from '../../images/star.png';
import { 
    HeroContainer, 
    HeroContent, 
    HeroH1, 
    HeroP, 
    HeroP2, 
    HeroP3, 
    HeroBtnWrapper, 
    ArrowForward, 
    ArrowRight, 
    HeroTextContainer, 
    HeroImageContainer, 
    TimerWrapper, 
    StarImage, 
    InfoText, 
    Divider,
    PromoCodeText
} from './HeroElements';

const HeroSection = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  }

  return (
    <HeroContainer>
        <HeroContent>
            <HeroTextContainer>
                <HeroH1>Humble <span>FUNDING</span></HeroH1>
                <HeroP></HeroP>
                <HeroP2>Your <span>Ally</span> In <span>Successful Trading</span></HeroP2>
                <HeroP3>Showcase your trading skills, pass simplified evaluation accounts, and reach payouts faster with Humble Funding. Skilled traders can achieve their goals without risking their own capital.</HeroP3>
                <HeroBtnWrapper>
                    <Button to="plans" onMouseEnter={onHover} onMouseLeave={onHover} primary='true' dark='true'>
                        Get Started{hover ? <ArrowForward /> : <ArrowRight />}
                    </Button>
                </HeroBtnWrapper>
            </HeroTextContainer>
            <HeroImageContainer>
                <PromoCodeText>DISCOUNT CODE: HMBL</PromoCodeText>
                <PromoCodeText>DISCOUNT CODE FOR ACTIVATION FEE: PAFEE10</PromoCodeText>
                <StarImage src={StarImageSrc} alt="Humble Funding" />
                <Divider />
                <InfoText>1 DAY PASS ON ALL ACCOUNT SIZES</InfoText>
                <Divider />
                <InfoText>24/7 LIVE EMAIL & DISCORD SUPPORT</InfoText>
                <Divider />
                <InfoText>100% PROFIT SPLIT UNTIL 25K + 25 FUNDED ACCOUNTS ALLOWED AT ONCE</InfoText>
                <Divider />
                <TimerWrapper>
                    <SaleTimer />
                </TimerWrapper>
            </HeroImageContainer>
        </HeroContent>
    </HeroContainer>
  );
}

export default HeroSection;
