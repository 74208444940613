import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 40px 20px;
  max-width: 800px;
  margin: auto;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`;

const Title = styled.h1`
  font-size: 32px;
  background: linear-gradient(90deg, black, #00bfff);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  text-align: center;
  margin-bottom: 30px;
`;

const SectionTitle = styled.h2`
  font-size: 24px;
  color: #333;
  margin-top: 20px;
  margin-bottom: 10px;
`;

const Paragraph = styled.p`
  font-size: 16px;
  color: #666;
  line-height: 1.6;
  margin: 10px 0;
`;

const DataDisclaimer = () => (
  <Container>
    <Title>Data Disclaimer</Title>

    <SectionTitle>Risk Disclosure</SectionTitle>
    <Paragraph>
      Trading in Futures and Forex carries significant risks and may not be suitable for all investors. The possibility exists that you could lose all or more than your initial investment. It is important to use only risk capital—money that can be lost without impacting your financial security or lifestyle. Investors should only consider trading if they have sufficient risk capital. Historical performance does not guarantee future results.
    </Paragraph>
    
    <SectionTitle>Operational Disclaimer</SectionTitle>
    <Paragraph>
      Humble Funding does not guarantee the uninterrupted or error-free operation of our trading platforms, data services, or related operations. Data is provided directly from third-party platforms and may be subject to variations and interruptions that are outside of our control. Humble Funding does not ensure the accuracy or reliability of this data for specific trading purposes. By participating in trading activities, traders accept the inherent risks involved. No refunds will be provided for any reason. We strongly encourage traders to engage in responsible and well-informed trading practices. For support and assistance, our team is readily available to help.
    </Paragraph>
  </Container>
);

export default DataDisclaimer;
