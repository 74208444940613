import React, {useEffect, useState} from 'react';
import {FaBars, FaDiscord} from 'react-icons/fa'
import { animateScroll as scroll } from 'react-scroll';
import { IconContext } from 'react-icons'; 
import Modal from '../../pages/Modal';  // Make sure to import your Modal component
import FaqPage from '../../pages/FaqPage';
import { Nav, 
    NavbarContainer, 
    NavLogo, 
    MobileIcon, 
    NavMenu, 
    NavItem, 
    NavLinks,
    NavBtn,
    NavBtnLink,
    GroupContainer
} from './NavbarElements'
import Logo from '../../images/hf.png'

const Navbar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false);
  const [isFAQOpen, setIsFAQOpen] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeNav);
    return () => {
      window.removeEventListener('scroll', changeNav);
    };
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  const toggleFAQ = () => {
    setIsFAQOpen(!isFAQOpen);  // Toggle the FAQ modal
  };

  return (
    <>
    <IconContext.Provider value={{color: '#fff'}}>
        <Nav scrollNav={scrollNav}>
            <NavbarContainer>
                <NavLogo to='/' onClick={toggleHome}><img src={Logo} alt="Humble Funding" style={{ maxWidth: '100%', height: 'auto' }} /></NavLogo>
            <MobileIcon onClick={toggle}>
                <FaBars />
            </MobileIcon >
            <GroupContainer>
            <NavMenu>
                <NavItem>
                <NavLinks to="about" smooth={true} duration={500} spy={true} exact='true' offset={-80} activeClass="active">ABOUT</NavLinks>
                </NavItem>
                <NavItem>
                <NavLinks to="process" smooth={true} duration={500} spy={true} exact='true' offset={-80} activeClass="active">OUR PROCESS</NavLinks>
                </NavItem>
                <NavItem>
                <NavLinks to="plans" smooth={true} duration={500} spy={true} exact='true' offset={-80} activeClass="active">PLANS</NavLinks>
                </NavItem>
                <NavItem>
                <NavLinks as="button" onClick={toggleFAQ}>
                  FAQ 
                </NavLinks>
                </NavItem>
                <NavItem>
                <NavLinks as="a" href="https://discord.gg/ZvDDTuzFus" target="_blank">
                    <FaDiscord />
                </NavLinks>
                </NavItem>
            </NavMenu>
            </GroupContainer>
            <NavBtn>             
                <NavBtnLink to="signup/signin">PURCHASE</NavBtnLink>
            </NavBtn>
            </NavbarContainer>
        </Nav>
        <Modal show={isFAQOpen} onClose={toggleFAQ}>
        <FaqPage /> {/* Include your FAQ content here */}
        </Modal>
    </IconContext.Provider>
    </>
  )
}

export default Navbar
